<template>
  <div class="grade">
    <div class="grade-mine">{{ info.name_en }}（{{ info.name_oth }}）</div>
    <div class="grade-body">
      <van-card
        :desc="item.name_oth"
        :title="item.name_en"
        v-for="(item, index) in grade"
        :key="item.id"
      >
        <!-- <template #price>
          <div style="font-size: 18px">¥ {{ item.name_en }}</div>
        </template> -->
        <template #thumb>
          <van-image
            width="50"
            height="50"
            :src="require('../assets/img/icon-leve' + index + '.png')"
          />
        </template>
        <template #footer>
          <van-button class="grade-btn" :class="'btn' + index" @click="toDetail(item.id)">{{
            $t("查看")
          }}</van-button>
        </template>
      </van-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Grade",
  data() {
    return {
      info: "",
      grade: [],
    };
  },
  mounted() {
    this.userCenter();
    this.gradeList();
  },
  methods: {
    userCenter() {
      // 获取用户数据
      this.$http.post("/v1/userCenter").then((res) => {
        if (res.code == 200) {
          this.info = res.data.grade;
        }
      });
    },
    gradeList() {
      // 等级列表
      this.$http.post("/v1/gradeList").then((res) => {
        if (res.code == 200) {
          this.grade = res.data.gradeList;
        }
      });
    },
    toDetail(grade) {
      this.$router.push({ path: "/gradeDetail", query: { grade } });
    },
  },
};
</script>

<style scoped>
.grade {
  min-height: 100vh;
  background: #f5f6fa;
}
.grade-mine {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  width: 100vw;
  background: #333;
  color: white;
}
.grade-body {
  padding: 5vw;
}
.van-card {
  box-shadow: 0 0 8px 0 #ccc;
  margin-bottom: 5vw;
  border-radius: 5px;
  /* background: white; */
}
.grade-btn {
  padding: 0 10px;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  color: white;
  border-radius: 3px;
}
.btn0 {
  background: linear-gradient(to right, #f9a470, #db854e);
}
.btn1 {
  background: linear-gradient(to right, #c8c7c5, #b3b3b3);
}
.btn2 {
  background: linear-gradient(to right, #ffd32f, #f5b82c);
}
.van-card__thumb {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>